<template>
    <div class="pass-filter" ref="filterPop" >
        <div class="pass-filter-panel" @click="showDetail">
            <span v-if="numJson.adtNum">{{ numJson.adtNum }}成人</span>
            <span v-if="numJson.chNum">{{ numJson.chNum }}儿童</span>
            <!-- <span v-if="babyNum">{{ babyNum }}婴儿</span> -->

            <i style="color: #C0C4CC" v-if="!filterVisible" class="el-icon-arrow-down"></i>
            <i style="color: #C0C4CC" v-else class="el-icon-arrow-up"></i>
        </div>
        <div class="pass-filter-list" v-show="filterVisible">
            <div class="pass-filter-li">
                <span class="filter-title">
                    <img src="../assets/adt-index-new.png" alt="">
                    成人
                </span>
                <span class="filter-mark">(成人 ≥ 12岁)</span>
                <el-input-number
                    v-model="numJson.adtNum"
                    @change="handleChangeAdtNum"
                    :min="Math.max(numJson.chNum/2,1)"
                    :max="9-numJson.chNum"
                    label="描述文字"
                ></el-input-number>
            </div>
            <div class="pass-filter-li">
                <span class="filter-title">
                    <img src="../assets/child-index-new.png" alt="">
                    儿童
                </span>
                <span class="filter-mark">(2岁 ≤ 儿童 ＜ 12岁)</span>
                <el-input-number
                    v-model="numJson.chNum"
                    @change="handleChangeChNum"
                    :min="0"
                    :max="Math.min(9 - numJson.adtNum, numJson.adtNum*2)"
                    label="描述文字"
                ></el-input-number>
            </div>
            <!-- <div class="pass-filter-li">
                <span class="filter-title">婴儿</span>
                <span class="filter-mark">(14天≤ 婴儿＜ 2岁 )</span>
                <el-input-number
                    v-model="babyNum"
                    @change="handleChangeBabyNum"
                    :min="1"
                    :max="10"
                    label="描述文字"
                ></el-input-number>
            </div>-->
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      filterVisible: false,
      numJson: {
        adtNum: 1,
        chNum: 0,
        babyNum: 0
      }
    };
  },
  mounted() {
    var _this = this;
    document.addEventListener("click", function(e) {
      if (_this.$refs.filterPop && !_this.$refs.filterPop.contains(e.target)) {
        _this.filterVisible = false;
      }
    });
  },
  methods: {
    handleChangeAdtNum() {

      this.$emit("changePassNum", this.numJson);
    },
    handleChangeChNum() {
      this.$emit("changePassNum", this.numJson);
    },
    // handleChangeBabyNum() {},
    showDetail() {
      this.filterVisible = !this.filterVisible;
    }
  }
};
</script>
<style lang="less">
.pass-filter {
  position: relative;
  width: 100px;
  color: #606266;
  &-panel {
    width: 100px;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    cursor: pointer;
    span {
      margin-right: 5px;
    }
  }
  &-list {
    position: absolute;
    width: 400px;
    // height: 300px;
    background: #ffffff;
    color: #000000;
    // position: relative;
    z-index: 100001;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #cccccc;
  }
  &-li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .filter-title {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  .filter-mark {
    color: #606266;
    width: 150px;
    text-align: left;
  }
}
</style>

