<template>
    <div class="filter-panel" v-show="showPannel">
        <p class="filter-panel-title">
            热门城市（可直接输入城市名称或城市拼音）
            <i class="el-icon-close" @click="close"></i>
        </p>
        <div class="filter-switch">
            <div class="filter-switch-item active-filter">国内</div>
        </div>
        <div class="city-area-tabs">
            <div
                class="tab-item"
                 style="line-height:30px"
                v-for="(item,index) in airportPannelData"
                v-bind:key="index"
                @click="airportPannelCurrentLabel=item"
            >{{item.groupName}}</div>
        </div>
        <div
            class="filter-city-list"
            v-if="airportPannelCurrentLabel.hotAirports!=null && airportPannelCurrentLabel.hotAirports!=undefined && airportPannelCurrentLabel.hotAirports.length>0"
        >
             <a  style="width:60px;"
                @click="handleAirportSelect(item)"
                v-for="(item,index) in airportPannelCurrentLabel.hotAirports"
                v-bind:key="index"
            >{{item.cityName}}</a>
        </div>
        <div
            class="filter-city-list"
            v-if="airportPannelCurrentLabel.airports!=null && airportPannelCurrentLabel.airports.length>0"
        >
            <el-row v-for="(group,index) in airportPannelCurrentLabel.airports" v-bind:key="index">
                <el-col :span="2">
                    <span style="color:red;font-weight:600;line-height:30px">{{group.groupName}}</span>
                </el-col>
                <el-col :span="22">
                    <a  style="width:80px;"
                        @click="handleAirportSelect(item)"
                        v-for="(item,index) in group.commonAirports"
                        v-bind:key="index"
                    >{{item.cityName}}</a>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {
  SELECT_AIRPORTS  
} from "@/store/mutation-types";
import { fetchAirportsPannelData } from "@/api/newsapi";
export default {
  data() {
    return {
      showPannel: this.showAirport,
      restaurants: [],
      flights: [],
      airportPannelData: [],
      airportPannelCurrentLabel: {
        hotAirports: [],
        airports: [],
        commonAirports: []
      }
    };
  },
  mounted() {
    this.loadAllAirportsData();
  },
  methods: {
    show() {
      this.showPannel = true;
    },
    close() {
      this.showPannel = false;
    },
    handleAirportSelect(airport) {
      this.showPannel = false;
      this.$emit("selected", airport);
    },
    loadAllAirportsData() {
      fetchAirportsPannelData({}).then(res => {
        Vue.ls.set(SELECT_AIRPORTS, res.data);
        this.airportPannelData = res.data.list;
        this.airportPannelCurrentLabel = this.airportPannelData[0];
      });
    }
  }
};
</script>

<style scoped lang='less'>
.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 68px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  z-index: 10001 !important;
  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 10%;
    width: 7px;
    height: 7px;
    border-top: 10px solid #ffffff;
    border-right: 10px solid #ffffff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(-45deg);
  }
}

.filter-panel-title {
  color: #9d9d9d;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-switch {
  display: flex;
  margin-top: 4px;
  margin-bottom: 6px;
}

.filter-switch-item {
  border: 1px solid #f87474;
  font-size: 14px;
  color: #666666;
  padding: 0 16px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}

.filter-switch-item:last-child {
  border-left: 0;
}

.active-filter {
  background-color: #d60039;
  color: #ffffff;
}

.city-area-tabs {
  display: flex;
}

.tab-item {
  border-bottom: 1px solid #d3d3d3;
  width: 65px;
  height: 23px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 23px;
  cursor: pointer;
  margin-right: 5px;
}

.filter-city-list a {
  display: inline-block;
  margin: 3px 3px 3px 0;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #4d4e4e;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
}

.filter-city-list a:hover {
  background-color: #d60039;
  color: #ffffff;
}
.el-icon-close {
  cursor: pointer;
  position: absolute;
  //   float: right;
  top: 10px;
  right: 10px;
}
</style>